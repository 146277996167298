import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Label, Select, TextInput } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { signupAdminStepSchema } from '../../schemas/auth.schemas';
import { selectSignup, setAdmin } from './signupSlice';
import { Country, CountryEnum } from '../../store/finegym-rtk-query-api';
import { getCountries, getTimezones } from '../../utils/checkTenant';

type Timezone = {
  code: string;
  name: string;
};

type Props = {
  readonly onNextStepClick: () => void;
  readonly onPrevStepClick: () => void;
};

type Inputs = {
  email: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  country: string;
  timezone: string;
};

export default function SignUpAdminStep({
  onNextStepClick,
  onPrevStepClick,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [countries, setCountries] = useState([] as Country[]);
  const [timezones, setTimezones] = useState([] as Timezone[]);

  const { registrantData } = useAppSelector(selectSignup);
  const schema = useMemo(() => signupAdminStepSchema(t), [t]);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        return;
      }
      await executeRecaptcha(action);
    },
    [executeRecaptcha],
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: registrantData.adminEmail,
      fullName: registrantData.adminFullName,
      country: registrantData.adminCountry,
      timezone: registrantData.adminTimezone,
    },
  });

  const selectedCountry = watch('country');
  const selectedTimezone = watch('timezone');

  useEffect(() => {
    const fetchCountries = async () => {
      const countriesResponse = await getCountries();
      setCountries(countriesResponse);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (countries?.length) {
      setValue('country', selectedCountry);
    }
  }, [setValue, countries, selectedCountry]);

  useEffect(() => {
    if (selectedCountry) {
      const fetchTimezones = async (countryCode: string) => {
        const timezonesResponse = await getTimezones({
          country: countryCode as CountryEnum,
        });
        if (timezonesResponse?.length) {
          setTimezones(timezonesResponse);
          if (timezonesResponse?.length === 1) {
            setValue('timezone', timezonesResponse[0].code);
          } else if (selectedTimezone) {
            setValue('timezone', selectedTimezone);
          }
        }
      };

      fetchTimezones(selectedCountry);
    } else {
      setTimezones([]);
    }
  }, [selectedCountry, setValue, selectedTimezone]);

  const submit = useCallback(
    async (formValues: Inputs) => {
      await handleReCaptchaVerify('signUpAdminStep');
      dispatch(
        setAdmin({
          adminEmail: formValues.email,
          adminFullName: formValues.fullName,
          adminPassword: formValues.password,
          adminCountry: formValues.country,
          adminTimezone: formValues.timezone,
        }),
      );
      onNextStepClick();
    },
    [dispatch, handleReCaptchaVerify, onNextStepClick],
  );

  return (
    <>
      <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
        {t('Admin Account Setup')}
      </h1>
      <p className="font-light text-gray-500 dark:text-gray-400 mb-4">
        {t(
          "Create your administrator account to manage your gym's digital presence effectively. This account will be your primary access to the platform, enabling you to control settings, manage memberships, and view insights.",
        )}
      </p>
      <form onSubmit={handleSubmit(submit)}>
        <div className="grid gap-5 mb-5 mt-6 sm:grid-cols-2">
          <div>
            <Label htmlFor="fullName" className="block mb-2">
              {t('Full name')}
            </Label>
            <TextInput
              {...register('fullName')}
              type="text"
              placeholder="John Doe"
              color="primary"
              autoComplete="off"
            />
            {errors.fullName && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors.fullName.message}
              </p>
            )}
          </div>
          <div>
            <Label htmlFor="email" className="block mb-2">
              {t('Email')}
            </Label>
            <TextInput
              {...register('email')}
              type="email"
              placeholder="john.doe@awesome-fitness.com"
              color="primary"
              autoComplete="new-email"
            />
            {errors.email && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors.email.message}
              </p>
            )}
          </div>
          <div>
            <Label htmlFor="password" className="block mb-2">
              {t('Password')}
            </Label>
            <TextInput
              {...register('password')}
              type="password"
              placeholder="••••••••"
              color="primary"
              autoComplete="new-password"
            />
            {errors.password && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors.password.message}
              </p>
            )}
          </div>
          <div>
            <Label htmlFor="confirmPassword" className="block mb-2">
              {t('Confirm password')}
            </Label>
            <TextInput
              {...register('confirmPassword')}
              type="password"
              placeholder="••••••••"
              color="primary"
              autoComplete="new-password"
            />
            {errors.confirmPassword && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
          <div>
            <Label htmlFor="country" className="block mb-2">
              {t('Select country')}
            </Label>
            <Select {...register('country')} color="primary">
              <option value={undefined} />
              {countries?.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Select>
            {errors.country && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors.country.message}
              </p>
            )}
          </div>
          <div>
            <Label htmlFor="timezone" className="block mb-2">
              {t('Select timezone')}
            </Label>
            <Select
              {...register('timezone')}
              color="primary"
              disabled={timezones?.length === 1}
            >
              {timezones?.map((timezone) => (
                <option key={timezone.code} value={timezone.code}>
                  {timezone.name}
                </option>
              ))}
            </Select>
            {errors.timezone && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                {errors.timezone.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex space-x-3 justify-end mt-10">
          <Button
            type="button"
            className="w-full"
            size="lg"
            color="gray"
            onClick={onPrevStepClick}
          >
            {t('Previous Step')}
          </Button>
          <Button type="submit" className="w-full" size="lg" color="primary">
            {t('Next Step')}
          </Button>
        </div>
      </form>
    </>
  );
}
